<!-- eslint-disable max-len -->
<template>
  <div class="lg:p-20 p-10 bg-orange-500 text-white">
    <p class="font-playfair lg:text-7xl text-4xl mb-5">Kia Ora, we are <span class="font-medium italic text-orange-300">Lustre Digital</span></p>
    <p class="font-lato lg:text-3xl text-xl mb-5">We create solutions to help Kiwi businesses get sh*t done.</p>
  </div>

  <main class="lg:p-20 p-10">
    <!-- <p class="text-3xl font-playfair mb-5 font-semibold border-b-4 border-orange-300 pb-2 text-orange-500">Some of our work</p>
    <Projects :projects="projects" /> -->
    <p class="text-3xl font-playfair mb-5 font-semibold border-b-4 border-orange-300 pb-2 text-orange-500">Get in touch</p>
    <p class="font-bold mb-2">George Bates</p>
    <p>+64 27 272 0421</p>
    <a href="mailto:george@lustredigital.co.nz"><p>george@lustredigital.co.nz</p></a>
    <p><a href="https://www.linkedin.com/in/george-bates-70719a167/">LinkedIn</a> | <a href="https://www.behance.net/georgebates">Behance</a> | <a href="https://github.com/georgewoofbates">GitHub</a></p>
  </main>
</template>

<script>
// import Projects from '@/components/Projects.vue';

export default {
  components: {
    // Projects,
  },
  data() {
    return {
      // projects: [],
    };
  },
  created() {
    this.projects = [
      {
        id: 1,
        title: 'Tradiemate',
        type: 'App Development',
        description: 'This is a test for TM',
        image: 'https://tradiemate-admin.s3.ap-southeast-2.amazonaws.com/static-images/Tradiemate-Banner.jpg',
      },
      {
        id: 2,
        title: 'SkyCity Matariki',
        type: 'Creative',
        description: 'This is a test for Matariki',
        image: 'https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/99b78a123791055.60f602c4809e9.png',
      },
    ];
  },
};
</script>
