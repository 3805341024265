import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';

createApp(App)
  .use(router)
  .use(VueGtag, {
    config: { id: 'G-FQCW21F59C' },
    router,
  })
  .mount('#app');
